<template>
	<div>
		<div class="video_top">
			<div class="mian">
				<div class="clearfix">
					<div class="fl video_info" :class="isShow ? 'video_infos' : ''">
						<div class="video_info_top clearfix">
							<div class="fl video_info_title">{{ info.title }}</div>
							<div class="fr clearfix video_info_reading"
								:style="{ paddingRight: isShow ? '60px' : '0px' }">
								<div class="fl clearfix video_info_reading_k">
									<img class="fl" style="width: 20px" src="../assets/images/yulan.svg" alt="" />
									<span class="fl">{{ info.browse_num }}</span>
								</div>
								<div class="fl clearfix">
									<img class="fl" style="width: 20px" src="../assets/images/shoucang.svg" alt="" />
									<span class="fl">{{ info.collect_num }}</span>
								</div>
							</div>
						</div>
						<div class="video_info_play">
							<div class="video_info_play_start" @click="playFun" v-if="!isPlay">
								<img class="video_info_play_start_thumb" :src="info.video_thumb" alt="" />
								<img class="playClick" src="../assets/images/play.png" alt="" />
								<div>{{ info.level_name }}</div>
							</div>

							<div v-show="isPlay" id="player"></div>
						</div>
						<div class="video_info_bottom clearfix">
							<div class="fl">{{ info.learned }}人已学</div>
							<div class="fr" :style="{ paddingRight: isShow ? '17px' : '0px' }">
								作品已申请版权登记保护
							</div>
						</div>
					</div>
					<div class="fr video_info_r" :style="{ width: isShow ? '0px' : '360px' }">
						<div class="video_info_pack" :class="isShow ? 'video_info_packs' : ''" @click="switchFun">
							<img src="../assets/images/video-bavk.png" alt="" />
						</div>
						<div class="clearfix" v-if="!isShow">
							<div class="fl video_info_upload" @click="downFun" :style="{ opacity: isShow ? '0' : '1' }">
								<img src="../assets/images/icon_mrtj_download@2x.png" alt="" />
								下载课程源文件
							</div>
							<div class="fr video_info_colloct" @click.stop="jionColloct()">
								<img v-if="info.is_collect == 1" src="../assets/images/sc_s.svg" alt="" />
								<img v-else src="../assets/images/sc.svg" alt="" />
								<!-- 收藏已收藏的字 -->
								<!-- <p :style="{color:info.is_collect == 1?'#5957FF':'#FFFFFF'}">{{info.is_collect == 1?'已收藏':'收藏'}}</p> -->
								<div class="collection_show" v-if="isColloctShow">
									<collection :collectionId="info.id" type="3" @closeFun.stop="closeFun"></collection>
								</div>
							</div>
						</div>

						<div class="video_info_r_list" :style="{ opacity: isShow ? '0' : '1' }">
							<div class="video_info_r_list_top">
								<div>推荐视频</div>
								<div class="video_info_r_list_top_more" @click="toVideoFun">
									更多视频
								</div>
							</div>
							<div class="video_info_r_list_scroll">
								<div class="video_info_r_item clearfix" v-for="(item, index) in info.video_rec"
									@click="videoToFun(item.id)" :key="index">
									<div class="fl video_info_r_item_img">
										<img v-lazy="item.thumb" alt="" />
									</div>
									<div class="fr video_info_r_item_r">
										<div class="omit1">{{ item.title }}</div>
										<div>
											{{ item.level_name }} | {{ item.font_style_name }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="video_info_share clearfix">
					<div class="fl material_detail_info_span">
						关键词：<span v-for="item in antistop" :key="item" @click="antFun(item)">{{ item }}</span>
					</div>
					<div class="fr info_share">
						<span>分享到：</span>
						<div>
							<img @click="$share.toQQ(info.title)" src="../assets/images/share1.png" alt="" />
							<img @click="$share.toQQ(info.title)" src="../assets/images/detailqq.png" alt="" />
						</div>
						<div>
							<img @click="$share.toWeibo(info.title)" src="../assets/images/share2.png" alt="" />
							<img @click="$share.toWeibo(info.title)" src="../assets/images/detailweibo.png" alt="" />
						</div>
						<div class="wxShare">
							<img src="../assets/images/share3.png" alt="" />
							<img src="../assets/images/detailweixin.png" alt="" />
							<div class="wxShare_box">
								<div>使用微信 扫一扫</div>
								<div id="qrcode" ref="qrcode"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="mian clearfix">
			<div class="video_detail">
				<div class="video_detail_tab clearfix">
					<div class="fl active">作业点评</div>
				</div>
				<div class="video_detail_work clearfix">
					<div class="video_detail_job">
						<div class="fl" style="position: relative">
							<div class="upload_pop" v-if="!headers.token" @click="is_login"></div>
							<div class="upload_el" @click="uploadVipFun">
								<img v-if="imageUrl" :src="imageUrl" class="up_img" />
								<div v-if="!imageUrl" class="el-upload__button">上传作业</div>
								<div v-if="!imageUrl" class="el-upload__text">
									单张图片大小5M以内支持jpg、png格式
								</div>
							</div>
							<el-upload v-show="false" ref="upload1" class="upload-demo" :show-file-list="false" drag :headers="headers"
								:action="$api.url + '/api/' + $face.defaultUpload" :on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload" accept=".png, .jpg, .jpeg">
								
							</el-upload>
							<div class="video_detail_job_close" v-if="imageUrl" @click="cancelJob">
								<img src="../assets/images/search_del.png" alt="" />
							</div>
						</div>
						<div class="fr video_detail_job_introduce">
							<textarea v-model="content" style="resize: none"></textarea>
						</div>
					</div>
					<div class="fr video_detail_job_submit" @click="taskUpload">提交</div>
				</div>
				<div class="video_detail_review_box">
					<div class="video_detail_review">全部点评</div>
					<div class="video_detail_review_list" v-if="task_id != ''">
						<div class="video_detail_user" v-for="(item, index) in comment" :key="index"
							:ref="'videotask' + index">
							<!-- 是否优秀 -->
							<div class="goods" v-if="item.is_good == 1"></div>
							<div :class="'goods goods'+item.activity_ranking"
								v-if="item.is_good == 0 && item.activity_ranking>0"></div>
							<div v-if="item.is_benren == 1" class="fr video_detail_user_time video_detail_user_del"
								style="margin-left: 20px; cursor: pointer">
								<div class="more_edit">
									<div class="el-icon-more"></div>
									<div class="more_edit_pop_top"></div>
									<div class="more_edit_pop">
										<div v-if="item.is_examine == 0" @click="editFun(1, item)">
											修改
										</div>
										<div @click="delpop(item.id)">删除</div>
										<!-- @click="deltaskFun(item.id)" -->
									</div>
								</div>
							</div>
							<div class="fr video_detail_user_time top_time">
								{{ item.last_time }}
							</div>
							<div class="video_detail_user_top clearfix">
								<div class="fl video_detail_user_top_l clearfix">
									<img class="fl" :src="item.user.thumb" alt=""
										@click="$parent.getUser(item.user_id)" />
									<div class="video_detail_user_top_l_info">
										<div>{{ item.user.username }}</div>
										<div v-if="item.content">{{ item.content }}</div>
									</div>
								</div>
							</div>
							<div class="video_detail_content">
								<div class="video_detail_user_img video_detail_user_imgs">
									<div class="reply_thumb">
										<img :src="item.thumb" alt="" @click="showImg(item.thumb)" />
									</div>
								</div>
								<div :class="item.is_like == 1 ? 'zan zan_active' : 'zan'" @click="good(item.id)">
									<span>{{ item.goods }}</span>
								</div>
							</div>

							<div class="video_detail_comment" v-show="item.comment">
								<div class="clearfix comment_reply">
									<div class="fl video_detail_user_top_l clearfix">
										<img class="fl" src="../assets/images/logo.jpg" alt="" />
										<div class="video_detail_user_top_l_info nopaddleft">
											<div>
												字体江湖 <span>点评</span> {{ item.user.username }}
											</div>
											<div>{{ item.comment }}</div>
										</div>
									</div>
									<div class="fr video_detail_user_time comment_time">
										{{ item.dp_last_time }}
									</div>
								</div>
								<div v-for="(items, indexs) in item.lower" :key="indexs">
									<div class="clearfix">
										<div class="fl video_detail_user_top_l clearfix nomarleft">
											<img class="fl" :src="items.user.thumb" alt="" />
											<div class="video_detail_user_top_l_info nopaddleft">
												<div>{{ items.user.username }}</div>
											</div>
										</div>
										<div class="fr video_detail_user_time">
											{{ items.dp_last_time }}
										</div>
									</div>
									<div class="video_detail_user_img nomarleft">
										<div class="reply_content">{{ items.content }}</div>
										<div class="reply_thumb">
											<img :src="items.thumb" alt="" @click="showImg(items.thumb)" />
											<div class="reply_edit" v-if="items.is_benren == 1">
												<div v-if="item.is_examine == 0" @click="editFun(1, items)">
													<img src="../assets/images/edit.svg" /><span>编辑作业</span>
												</div>
												<div @click="delpop(items.id)">
													<img src="../assets/images/delete.svg" /><span>删除</span>
												</div>
											</div>
										</div>
									</div>
									<div class="clearfix" v-if="items.comment">
										<div class="fl video_detail_user_top_l clearfix">
											<img class="fl" src="../assets/images/logo.jpg" alt="" />
											<div class="video_detail_user_top_l_info nopaddleft">
												<div>
													字体江湖 <span>点评</span> {{ items.user.username }}
												</div>
												<div>{{ items.comment }}</div>
											</div>
										</div>
										<div class="fr video_detail_user_time">
											{{ item.dp_last_time }}
										</div>
									</div>
								</div>

								<div class="video_detail_edit" v-if="
                    (item.is_benren == 1 &&
                      item.comment &&
                      item.lower.length < 1) ||
                    (item.is_benren == 1 &&
                      item.lower.length > 0 &&
                      item.lower[item.lower.length - 1].comment)
                  ">
									<!-- <el-upload class="upload-demo" :show-file-list="false" drag :headers="headers"
										:action="$api.url + '/api/' + $face.defaultUpload"
										:on-success="handleAvatarSuccessFun" :before-upload="beforeAvatarUpload"
										accept=".png, .jpg, .jpeg"> -->
									<div @click="
                      editFun(
                        2,
                        item.lower.length < 1
                          ? item
                          : item.lower[item.lower.length - 1]
                      )
                    ">
										修改作业>
									</div>
									<!-- </el-upload> -->
								</div>
							</div>
						</div>
					</div>

					<div class="video_detail_review_list" v-if="info.task.length > 0">
						<div class="video_detail_user" v-for="(item, index) in info.task" :key="index"
							:ref="'videotask' + index">

							<!-- 是否优秀 -->
							<div class="goods" v-if="item.is_good == 1"></div>
							<div :class="'goods goods'+item.activity_ranking"
								v-if="item.is_good == 0 && item.activity_ranking>0"></div>
							<div class="fr video_detail_user_time video_detail_user_del"
								style="margin-left: 20px; cursor: pointer" v-if="item.is_benren == 1">
								<div class="more_edit">
									<div class="el-icon-more"></div>
									<div class="more_edit_pop_top"></div>
									<div class="more_edit_pop">
										<div v-if="item.is_examine == 0" @click="editFun(1, item)">
											修改
										</div>
										<div @click="delpop(item.id)">删除</div>
										<!-- @click="deltaskFun(item.id)" -->
									</div>
								</div>
							</div>
							<div class="video_detail_user_time top_time">
								{{ item.last_time }}
							</div>
							<div class="video_detail_user_top clearfix">
								<div class="fl video_detail_user_top_l clearfix">
									<img class="fl" :src="item.user.thumb" alt=""
										@click="$parent.getUser(item.user_id)" />
									<div class="video_detail_user_top_l_info">
										<div>{{ item.user.username }}</div>
										<div v-if="item.content">{{ item.content }}</div>
									</div>
								</div>
							</div>
							<div class="video_detail_content">
								<div class="video_detail_user_img video_detail_user_imgs">
									<div class="reply_thumb">
										<img :src="item.thumb" alt="" @click="showImg(item.thumb)" />
									</div>
								</div>
								<div :class="item.is_like == 1 ? 'zan zan_active' : 'zan'" @click="good(item.id)">
									<span>{{ item.goods }}</span>
								</div>
							</div>

							<div class="video_detail_comment" v-show="item.comment">
								<div class="clearfix comment_reply">
									<div class="fl video_detail_user_top_l clearfix">
										<img class="fl" src="../assets/images/logo.jpg" alt="" />
										<div class="video_detail_user_top_l_info nopaddleft">
											<div>
												字体江湖 <span>点评</span> {{ item.user.username }}
											</div>
											<div>{{ item.comment }}</div>
										</div>
									</div>
									<div class="fr video_detail_user_time comment_time">
										{{ item.dp_last_time }}
									</div>
								</div>
								<div v-for="(items, indexs) in item.lower" :key="indexs">
									<div class="clearfix">
										<div class="fl video_detail_user_top_l clearfix nomarleft">
											<img class="fl" :src="items.user.thumb" alt="" />
											<div class="video_detail_user_top_l_info nopaddleft">
												<div>{{ items.user.username }}</div>
											</div>
										</div>
										<div class="fr video_detail_user_time">
											{{ items.dp_last_time }}
										</div>
									</div>
									<div class="video_detail_user_img nomarleft">
										<div class="reply_content">{{ items.content }}</div>
										<div class="reply_thumb">
											<img :src="items.thumb" alt="" @click="showImg(items.thumb)" />
											<div class="reply_edit" v-if="items.is_benren == 1">
												<div v-if="item.is_examine == 0" @click="editFun(1, items)">
													<img src="../assets/images/edit.svg" /><span>编辑作业</span>
												</div>
												<div @click="delpop(items.id)">
													<img src="../assets/images/delete.svg" /><span>删除</span>
												</div>
											</div>
										</div>
									</div>
									<div class="clearfix" v-if="items.comment">
										<div class="fl video_detail_user_top_l clearfix">
											<img class="fl" src="../assets/images/logo.jpg" alt="" />
											<div class="video_detail_user_top_l_info nopaddleft">
												<div>
													字体江湖 <span>点评</span> {{ items.user.username }}
												</div>
												<div>{{ items.comment }}</div>
											</div>
										</div>
										<div class="fr video_detail_user_time">
											{{ item.dp_last_time }}
										</div>
									</div>
								</div>

								<div class="video_detail_edit" v-if="
                      (item.is_benren == 1 &&
                        item.comment &&
                        item.lower.length < 1) ||
                      (item.is_benren == 1 &&
                        item.lower.length > 0 &&
                        item.lower[item.lower.length - 1].comment)
                    ">
									<!-- <el-upload class="upload-demo" :show-file-list="false" drag :headers="headers"
										:action="$api.url + '/api/' + $face.defaultUpload"
										:on-success="handleAvatarSuccessFun" :before-upload="beforeAvatarUpload"
										accept=".png, .jpg, .jpeg"> -->
									<div @click="
                        editFun(
                          2,
                          item.lower.length < 1
                            ? item
                            : item.lower[item.lower.length - 1]
                        )
                      ">
										修改作业>
									</div>
									<!-- </el-upload> -->
								</div>
							</div>
						</div>
					</div>
					<el-empty v-if="task_id=='' && info.task.length==0" description="暂无数据"></el-empty>
				</div>
			</div>
		</div>

		<div class="imgDolg" v-show="imgPreview.show" @click.stop="imgPreview.show = false">
			<i class="el-icon-close" id="imgDolgClose" @click.stop="imgPreview.show = false"></i>
			<img @click.stop="imgPreview.show = true" :src="imgPreview.img" />
		</div>
		<div class="background" v-show="alertDel">
			<div class="alert">
				<div class="">确定删除这条作业吗？</div>
				<div class="alert_btn">
					<div class="alert_btn_cencel" @click="delpop(0)">取消</div>
					<div class="alert_btn_sure" @click="deltaskFun">确定</div>
				</div>
			</div>
		</div>
		<div class="background" v-show="editShow">
			<div class="edit_task_pop">
				<div class="edit_task_pop_head">
					<h2>编辑作业</h2>
					<a><i class="el-icon-close" @click="editTaskClose"></i></a>
				</div>
				<div class="edit_task_pop_content">
					<div class="edit_task_pop_content_upload">
						<!-- <div class="task_pop_thumb">
              <img :src="editImage" />
            </div> -->
						<div class="task_pop_upload dailyWord_upload_img">
							<!-- <button>重新上传</button> -->
							<el-upload class="upload-demo" :show-file-list="false" drag :headers="headers"
								:action="$api.url + '/api/' + $face.defaultUpload" :on-success="handleAvatarSuccessFun"
								:before-upload="beforeAvatarUpload" accept=".png, .jpg, .jpeg">
								<img v-if="editImage" :src="editImage" class="up_img" />
								<template v-else>
									<i class="upload_icon"></i>
									<div class="el-upload__text">
										点击 / 拖拽图片文件到这里上传
									</div>
									<div class="el-upload__tip">
										单张图片大小5M以内 ( 支持jpg、png格式 )
									</div>
								</template>
							</el-upload>
						</div>
					</div>
					<div class="task_pop_desc">
						<textarea v-model="editCon" placeholder="请发表你的作业描述，以及相关疑问"></textarea>
					</div>
					<div class="task_pop_btn">
						<button @click="editTaskFun">提交作业</button>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 下载课程提示不是会员的弹窗 -->
		<div class="memberMask" v-if="membershow">
			<div class="member_con">
				<img class="member_close" @click="closemember" src="../assets/images/login-close.png" alt="">
				<div class="member_title">
					<div class="member_bt" v-html="membershow_text"></div>
					<div class="member_tips">-还可拥有更多会员权益-</div>
				</div>
				<div class="member_nr">
					<div class="member_item">
						<img src="../assets/images/tu1.png" alt="">
						系统视频教程
					</div>
					<div class="member_item">
						<img src="../assets/images/tu2.png" alt="">
						作业100%点评
					</div>
					<div class="member_item">
						<img src="../assets/images/tu3.png" alt="">
						优秀作业奖励
					</div>
					<div class="member_item">
						<img src="../assets/images/tu4.png" alt="">
						设计步骤展示
					</div>
					<div class="member_item">
						<img src="../assets/images/tu5.png" alt="">
						内容持续更新
					</div>
				</div>
				<div class="member_btn" @click="removeFun">
					立即开通
				</div>
			</div>
		</div>
		
		<!-- 上传作业，提交 提示不是会员的弹窗 -->
		<!-- <div class="memberMask" v-if="membershow_one">
			<div class="member_con">
				<img class="member_close" @click="closemember_one" src="../assets/images/login-close.png" alt="">
				<div class="member_title">
					<div class="member_bt">开通会员&nbsp;&nbsp;即可提交作业获取点评</div>
					<div class="member_tips">-还可拥有更多会员权益-</div>
				</div>
				<div class="member_nr">
					<div class="member_item">
						<img src="../assets/images/tu1.png" alt="">
						系统视频教程
					</div>
					<div class="member_item">
						<img src="../assets/images/tu2.png" alt="">
						作业100%点评
					</div>
					<div class="member_item">
						<img src="../assets/images/tu3.png" alt="">
						优秀作业奖励
					</div>
					<div class="member_item">
						<img src="../assets/images/tu4.png" alt="">
						设计步骤展示
					</div>
					<div class="member_item">
						<img src="../assets/images/tu5.png" alt="">
						内容持续更新
					</div>
				</div>
				<div class="member_btn"  @click="removeFun">
					立即开通
				</div>
			</div>
		</div> -->
	</div>
</template>

<script>
	const polyvPlayer = window.polyvPlayer;
	const wx = require("weixin-js-sdk");
	import QRCode from "qrcodejs2";
	import collection from "../components/collection.vue";
	export default {
		name: "videoDetail",
		components: {
			collection,
		},
		data() {
			return {
				id: "", //视频id
				info: {
					task: [],
				}, //详情
				videoList: [{}, {}, {}, {}],
				antistop: [], //关键词
				headers: {
					token: localStorage.getItem("access_token"),
				},
				imageUrl: "", //上传图片路径
				imageUrl2: "", //修改上传图片路径
				content: "", //作业介绍

				isShow: false,

				isPlay: false, //开始播放
				// player: null,
				playerHook: 0,
				//保利威视视频
				vodPlayerJs: "//player.polyv.net/resp/vod-player-drm/canary/player.js",

				vid: "88083abbf5bcf1356e05d39666be527a_8",
				startTime: 0,

				isColloctShow: false, //收藏

				imgPreview: {
					img: "",
					show: false,
				},

				relation_id: "", //作业id
				alertDel: false,
				del_id: 0,

				editShow: false, //编辑作业
				editImage: "",
				editCon: "", //编辑作业描述
				editType: 1, //编辑作业，修改作业
				otimer: null,
				userInfo: {},
				imghight: 0,
				comment: [],
				task_id: "",

				// 开通会员弹出层
				mem_vip:'',
				membershow:false,
				membershow_text:'',
			};
		},
		mounted() {
			// if (window.history && window.history.pushState) {
			//     history.pushState(null, null, document.URL);
			//     window.addEventListener('popstate', this.goPageBefore, false);
			// }

			this.$parent.routerIndex = 3;
			this.$parent.showIndex = 1;
			this.id = this.$route.query.id;
			this.task_id = this.$route.query.task_id;
			this.detailFun(this.id);
			if (this.task_id != "" && this.task_id != undefined) {
				this.commentFun();


			}
			//  document.removeEventListener("click", this.closeMsg);
			let self = this;
			this.$nextTick(function() {
				document.addEventListener("keydown", function(e) {
					//此处填写你的业务逻辑即可
					if (e.keyCode == 27) {
						self.imgPreview.img = "";
						self.imgPreview.show = false;
					}
				});
			});
		},
		player: null,
		computed: {
			player: function() {
				return this.playerHook || this.$options.player;
			},
		},
		methods: {
			//
			detailFun(id, e) {
				var that = this;
				var params = {
					id: id,
				};
				this.$api.POST(this.$face.videoInfo, params, function(res) {
					that.info = res.data;
						if(that.info.is_gratis==1){
							that.$parent.routerIndex = 10;
						}else{
							that.$parent.routerIndex = 3;
						}

					for (let i in that.info.task) {
						if (that.task_id == that.info.task[i].id) {
							that.info.task.splice(i, 1);
						} else {
							if (that.info.task[i].user.thumb.indexOf("http") == -1) {
								console.log(that.info.task[i].user.thumb);
								that.info.task[i].user.thumb = require("../assets/images/head.png");
							}
							for (let i2 in that.info.task[i].lower) {
								if (that.info.task[i].lower[i2].user.thumb.indexOf("http") == -1) {
									that.info.task[i].lower[
										i2
									].user.thumb = require("../assets/images/head.png");
								}
							}
						}

					}
					that.$nextTick(() => {
						if (localStorage.getItem("workuser")) {
							that.info.task.forEach((item, index) => {
								if (localStorage.getItem("workuser") == item.user_id) {
									document.documentElement.scrollTop =
										that.$refs["videotask" + index][0].offsetTop - 100 || 0;
								}
							});
						} else {
							if (that.task_id != '' && that.task_id != undefined) {
								console.log('task_id', that.task_id);
								document.documentElement.scrollTop = 1200;
							} else {
								document.documentElement.scrollTop = 0;
							}
						}
						localStorage.removeItem("workuser");
					});
					if (e != "del" && e != "add") {
						var label = "";
						if (res.data.label.indexOf("，") != -1) {
							label = res.data.label.replace(/，/g, ",");
						} else {
							label = res.data.label;
						}
						that.antistop = label.split(",");
						that.playerTimeFun(res.data.id);
						that.$nextTick(() => {
							let qrcode = new QRCode("qrcode", {
								width: 100, // 设置宽度，单位像素
								height: 100, // 设置高度，单位像素
								text: "https://m.zitisheji.com/#/pages/video/uni_videodetail?id=" +
									that.id, // 设置二维码内容或跳转地址
							});
						});
					}
				});
			},
			playerTimeFun(id) {
				this.startTime = 0;
				var that = this;
				var params = {
					video_id: id,
				};
				this.$api.POST(this.$face.videoPlayrecord, params, function(res) {
					that.startTime = res.data.list_time;
					if (that.startTime > 0) {
						// that.playFun();
					}
				});
			},

			playFun() {
				if (
					localStorage.getItem("access_token") == "" ||
					localStorage.getItem("access_token") == undefined ||
					localStorage.getItem("access_token") == null
				) {
					this.$parent.loginShow();
					return;
				}
				// 是否是会员
				if(JSON.parse(localStorage.getItem("userInfo")).video_vip == 0 && this.info.is_gratis==0){
					this.membershow_text = '开通会员&nbsp;&nbsp;轻松学习海量视频';
					this.membershow = true;
					return;
				}

				var that = this;
				var params = {
					video_id: this.info.id,
				};
				this.$api.POST(this.$face.videoPlay, params, function(res) {
					that.isPlay = true;
					that.$nextTick(() => {
						that.loadPlayerScript(that.loadPlayer);
					});
				});
			},
			//保利威视播放视频
			loadPlayerScript(callback) {
				if (!window.polyvPlayer) {
					const myScript = document.createElement("script");
					myScript.setAttribute("src", this.vodPlayerJs);
					myScript.onload = callback;
					document.body.appendChild(myScript);
				} else {
					callback();
				}
			},

			loadPlayer() {
				const polyvPlayer = window.polyvPlayer;
				this.$options.player = polyvPlayer({
					wrap: "#player",
					width: "100%",
					height: "100%",
					autoplay: true,
					forceH5: true,
					forceHTML5: true,
					vid: this.info.video_id,
					playsafe: this.info.video_token.token,
					ts: this.info.video_token.ts,
					sign: this.info.video_token.sign,
					watchStartTime: this.startTime || 0,
					df: 3,
				});
				console.log("11111", this.$options.player);
				this.$options.player.on("s2j_onPlayOver", (...params) => {
					this.playOverFun();
				});
				this.$options.player.on("s2j_onVideoPlay", (...params) => {
					console.log(this.startTime);
					this.polling();
				});
				this.$options.player.on("s2j_onVideoPause", (...params) => {
					clearInterval(this.otimer);
				});
				this.$options.player.on("s2j_onPlayOver", (...params) => {
					clearInterval(this.otimer);
				});
			},

			commentFun() {
				var that = this;
				var params = {
					task_id: this.task_id,
				};
				this.$api.POST(this.$face.comment, params, function(res) {
					if (res.code == 200) {
						that.comment = res.data;

					}
				});
			},

			playOverFun() {
				var that = this;
				var params = {
					video_id: this.info.id,
				};
				this.$api.POST(this.$face.videoPlayok, params, function(res) {});
			},

			// 切换
			switchFun() {
				this.isShow = !this.isShow;
			},

			// 下载源文件
			downFun() {
				
				if ( localStorage.getItem("access_token") == "" || localStorage.getItem("access_token") == undefined || localStorage.getItem("access_token") == null ) {
					this.$parent.loginShow();
					return;
				}
				// 是否是会员
				if(JSON.parse(localStorage.getItem("userInfo")).video_vip == 0){
					this.membershow_text = '开通会员&nbsp;&nbsp;即可下载';
					this.membershow = true;
					return;
				}
				var that = this;
				var params = {
					id: this.info.id,
				};
				this.$api.POST(this.$face.videoDown, params, function(res) {
					
					const a = document.createElement("a");
					//   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
					a.href = res.data.source_file; // 完整的url则直接使用
					// document.body.appendChild(a)
					a.click();
				});
			},
			removeFun(){
				this.$router.push({
					name: "vip"
				});
			},
			handleAvatarSuccess(res, file) {
				this.imageUrl = res.initialPreview[0];
				this.imghight = res.initialPreviewConfig[0].high;
			},
			cancelJob() {
				this.imageUrl = "";
			},
			uploadVipFun() {
				// 是否是会员
				if(JSON.parse(localStorage.getItem("userInfo")).video_vip == 0){
					this.membershow_text = '开通会员&nbsp;&nbsp;即可提交作业获取点评';
					this.membershow = true;
					return;
				}else{
					this.$refs.upload1.$refs["upload-inner"].handleClick();
				}
			},
			beforeAvatarUpload(file) {
				// const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 5;

				// if (!isJPG) {
				// this.$message.error('上传头像图片只能是 JPG 格式!');
				// }
				if (!isLt2M) {
					this.$utile.prompt("error", "上传图片大小不能超过 5MB!");
				}
				return isLt2M;
			},
			is_login() {
				this.$parent.loginShow();
			},
			// 提价作业
			taskUpload() {
				if (
					localStorage.getItem("access_token") == "" ||
					localStorage.getItem("access_token") == undefined ||
					localStorage.getItem("access_token") == null
				) {
					this.$parent.loginShow();
					return;
				}

				if (!this.imageUrl) {
					this.$utile.prompt("success", "请上传作业后提交");
					return false;
				}
				
				// 是否是会员
				if(JSON.parse(localStorage.getItem("userInfo")).video_vip == 0){
					this.membershow_text = '开通会员&nbsp;&nbsp;即可提交作业获取点评';
					this.membershow = true;
					return;
				}
				var that = this;
				var params = {
					video_id: this.info.id,
					content: this.content,
					thumb: this.imageUrl,
					imghight: this.imghight,
				};
				this.$api.POST(this.$face.videoTask, params, function(res) {
					that.$utile.prompt("success", "作业已提交，等待后台审核");
					that.detailFun(that.id, "add");
					that.imageUrl = "";
					that.content = "";
				});
			},

			//修改作业
			editFun(el, row) {
				console.log(row);
				this.editType = el;
				if (el == 1) {
					this.editImage = row.thumb;
					this.editCon = row.content;
				}
				this.relation_id = row.id;
				this.editShow = true;
			},
			//关闭修改作业
			editTaskClose() {
				this.editImage = "";
				this.editCon = "";
				this.editShow = false;
			},
			//
			handleAvatarSuccessFun(res, file) {
				this.editImage = res.initialPreview[0];
				this.imghight = res.initialPreviewConfig[0].high;
			},
			editTaskFun() {
				if (!this.editImage) {
					this.$utile.prompt("success", "请上传作业后提交");
					return false;
				}
				if (this.editType == 1) {
					this.editxFun();
				} else {
					var that = this;
					var params = {
						relation_id: this.relation_id,
						video_id: this.info.id,
						content: this.editCon,
						thumb: this.editImage,
						imghight: this.imghight,
					};
					this.$api.POST(this.$face.videoTaskedit, params, function(res) {
						that.$utile.prompt("success", "作业已提交，等待后台审核");
						that.detailFun(that.id, "add");
						that.editTaskClose();
					});
				}
			},
			editxFun() {
				var that = this;
				var params = {
					id: this.relation_id,
					content: this.editCon,
					thumb: this.editImage,
				};
				this.$api.POST("video/edit", params, function(res) {
					that.$utile.prompt("success", "作业已提交，等待后台审核");
					that.detailFun(that.id, "add");
					that.editTaskClose();
				});
			},

			videoToFun(id) {
				// this.$router.push({
				//   name: "refresh",
				//   query: {
				//     id: id,
				//   },
				// });
				if (id) {
					let routeUrl = this.$router.resolve({
						name: "videoDetail",
						query: {
							id: id,
						},
					});
					window.open(routeUrl.href, "_blank");
				} else {
					let routeUrl = this.$router.resolve({
						name: "video",
					});
					window.open(routeUrl.href, "_blank");
				}
			},

			//收藏
			jionColloct() {
				if (localStorage.getItem("access_token")) {
					if (this.info.is_collect == 1) {
						//取消收藏
						var that = this;
						var params = {
							coll_id: this.info.id,
							type: 3,
						};
						this.$api.POST(this.$face.collectionColl, params, function(res) {
							that.$utile.prompt("success", "取消收藏");
							that.info.is_collect = 0;
							that.info.collect_num--;
						});
					} else {
						this.isColloctShow = true;
						document.addEventListener("click", this.closeMsg);
					}
				} else {
					this.$parent.loginShow();
				}
			},
			//关闭收藏
			closeFun(e) {
				console.log(1);
				document.removeEventListener("click", this.closeMsg);
				this.isColloctShow = false;
				if (e != 1) {
					this.info.is_collect = 1;
					this.info.collect_num++;
				}
			},
			//删除确认弹框
			delpop(id) {
				this.del_id = id;
				this.alertDel = !this.alertDel;
			},
			antFun(e) {
				//localStorage.setItem("clearAll", 3);
				console.log(e);
				localStorage.setItem("video_name", e.replace(/(^\s*)|(\s*$)/g, ""));
				let routeUrl = this.$router.resolve({
					name: "video",
				});
				window.open(routeUrl.href, "_blank");
			},

			closeMsg(ev) {
				console.log(1);
				let dom = document.getElementById("child");
				if (dom) {
					if (!dom.contains(ev.target)) {
						this.isColloctShow = false;
					}
				}
				document.removeEventListener("click", this.closeMsg);
			},

			// 查看大图
			showImg(e) {
				if (e) {
					this.imgPreview.img = e;
					this.imgPreview.show = true;
				}
			},

			//点赞
			good(id) {
				var that = this;
				var params = {
					task_id: id,
				};
				this.$api.POST(this.$face.good, params, function(res) {
					if (res.code == 200) {
						for (let i in that.info.task) {
							if (that.info.task[i].id == id) {
								if (res.msg == "取消成功") {
									that.info.task[i].is_like = 0;
									that.info.task[i].goods = that.info.task[i].goods - 1;
								} else {
									that.info.task[i].is_like = 1;
									that.info.task[i].goods = that.info.task[i].goods + 1;
								}
							}
						}
					}
				});
			},

			//删除本人作业
			deltaskFun() {
				var that = this;
				var params = {
					id: this.del_id,
				};
				this.$api.POST(this.$face.videoTaskdel, params, function(res) {
					that.$utile.prompt("success", "删除作业成功");
					that.detailFun(that.id, "del");
					that.alertDel = false;
				});
			},

			//更多视频
			toVideoFun() {
				localStorage.setItem("clearAll", 1);
				this.$router.push({
					name: "refresh",
					query: {
						usernext: "video",
					},
				});
			},
			//监听返回事件
			// goPageBefore(e){
			//     // this.$router.push({name:'video'})
			//     console.log(e)
			// }
			//轮询
			polling() {
				clearInterval(this.otimer);
				this.otimer = setInterval(() => {
					this.keep();
				}, 15000);
			},
			keep() {
				var that = this;
				if (this.$options.player) {
					// if (this.startTime <= this.$options.player.j2s_getCurrentTime()) {
					this.$api.POST(
						this.$face.videoPlaypause, {
							video_id: this.info.id,
							list_time: this.$options.player.j2s_getCurrentTime(),
							total_time: this.$options.player.j2s_getDuration(),
						},
						function(res) {}
					);
					// }
				}
			},
			closemember(){
				this.membershow = false;
			},
			closemember_one(){
				this.membershow_one = false;
			},
		},
		destroyed() {
			this.keep();
			clearInterval(this.otimer);
			this.$options.player.destroy();
			// window.removeEventListener('popstate', this.goPageBefore, false);
		},
		activated() {
			this.id = this.$route.query.id;
			this.detailFun(this.id);
		
		},
		watch: {
			"$route.query.id": function() {
				var that = this;
				if (this.$options.player) {
					if (this.startTime <= this.$options.player.j2s_getCurrentTime()) {
						this.$api.POST(
							this.$face.videoPlaypause, {
								video_id: this.info.id,
								list_time: this.$options.player.j2s_getCurrentTime(),
								total_time: this.$options.player.j2s_getDuration(),
							},
							function(res) {
								that.$options.player.destroy();
								clearInterval(that.otimer);
							}
						);
					}
				}
				this.id = this.$route.query.id;
				this.detailFun(this.id);
			},
			"$store.state.userInfo"(newval) {
				this.headers.token = localStorage.getItem("access_token");
			},

		},
	};
</script>

<style scoped>
	.video_top {
		width: 100%;
		height: 766px;
		background-color: #292929;
		padding-top: 38px;
		box-sizing: border-box;
	}

	.video_info_top {
		margin-bottom: 16px;
	}

	.video_info_title {
		font-size: 22px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #ffffff;
	}

	.video_info_reading,
	.video_info_reading>div {
		line-height: 20px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: rgba(255, 255, 255, 0.5);
	}

	.video_info_reading>div:nth-child(1) img {
		/* margin-top: 3px; */
		margin-right: 6px;
	}

	.video_info_reading>div:nth-child(2) img {
		/* margin-top: 2px; */
		margin-right: 6px;
	}

	.video_info_reading_k {
		margin-right: 22px;
	}

	.video_info {
		width: 1040px;
	}

	.video_infos {
		width: 100%;
	}

	.video_info_play,
	#player {
		width: 100%;
		height: 585px;
	}

	.video_info_play_start {
		position: relative;
		cursor: pointer;
	}

	.video_info_play_start_thumb {
		max-width: 100%;
		height: 585px;
		margin: 0 auto;
	}

	.video_info_play_start div {
		width: 70px;
		height: 40px;
		background-color: #5957ff;
		border-radius: 10px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 40px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align: center;
		position: absolute;
		top: 15px;
		left: 17px;
	}

	.playClick {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 199;
		cursor: pointer;
	}

	.video_info_bottom {
		line-height: 47px;
		background-color: #2f3236;
		color: rgba(255, 255, 255, 0.42);
		padding-left: 17px;
		box-sizing: border-box;
	}

	.video_info_r {
		margin-top: 36px;
		width: 360px;
		height: 632px;
		background-color: #2f3236;
		padding: 23px 0 36px;
		box-sizing: border-box;
		position: relative;
	}

	.video_info_pack {
		width: 36px;
		height: 36px;
		background-color: #2f3236;
		position: absolute;
		top: -36px;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.video_info_packs img {
		transform: rotate(180deg);
	}

	.video_info_upload {
		width: 256px;
		height: 42px;
		background-color: #5957ff;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: #ffffff;
		cursor: pointer;
		margin: 0 auto 21px;
		margin-left: 20px;
	}

	.video_info_upload img {
		width: 10px;
		height: 13px;
		margin-right: 10px;
	}

	.video_info_colloct {
		width: 38px;
		margin-right: 20px;
		position: relative;
		text-align: center;
		cursor: pointer;
		font-size: 12px;
		line-height: 12px;
		color: #5957ff;
	}

	.video_info_colloct>img {
		width: 19px;
		height: 19px;
		margin: 11px auto;
	}

	.collection_show {
		position: absolute;
		left: 40px;
		top: -4px;
	}

	.video_info_r_list {
		border-top: 1px solid #464b4c;
	}

	.video_info_r_list_top {
		padding: 21px 20px 0;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		font-size: 18px;
		color: #fff;
		margin-bottom: 15px;
	}

	.video_info_r_list_top .video_info_r_list_top_more {
		font-size: 12px;
		color: #ffffff;
		cursor: pointer;
	}

	.video_info_r_list_scroll {
		max-height: 444px;
		overflow: hidden;
		overflow-y: auto;
	}

	.video_info_r_item {
		padding: 9px 20px;
	}

	.video_info_r_item .video_info_r_item_img {
		width: 140px;
		height: 82px;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
	}

	.video_info_r_item .video_info_r_item_img img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.video_info_r_item_r {
		width: 160px;
	}

	.video_info_r_item_r>div:nth-child(1) {
		font-size: 14px;
		line-height: 14px;
		margin-top: 6px;
		margin-bottom: 42px;
		color: #fff;
	}

	.video_info_r_item_r>div:nth-child(2) {
		font-size: 12px;
		line-height: 12px;
		color: #999;
	}

	.video_info_r_item:hover {
		background-color: #464647;
	}

	.video_info_share {
		margin-top: 16px;
	}

	.material_detail_info_span {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 22px;
		letter-spacing: 0px;
		color: #666;
	}

	.material_detail_info_span span {
		margin-right: 28px;
		transition: 0.3s;
		cursor: pointer;
	}

	.material_detail_info_span span:hover {
		color: #5957ff;
		text-decoration: underline;
	}

	.info_share {
		display: flex;
		align-items: center;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		letter-spacing: 0px;
		color: #666;
	}

	.info_share img {
		margin-right: 12px;
	}

	.info_share>div img:nth-child(2) {
		display: none !important;
	}

	.info_share>div:hover img:nth-child(2) {
		display: block !important;
	}

	.info_share>div:hover img:nth-child(1) {
		display: none !important;
	}

	.wxShare>img {
		margin-right: 0;
	}

	/* 课程介绍，作业点评 */
	.video_detail {
		width: 100%;
		background-color: #ffffff;
		border-radius: 10px;
		margin: 31px 0 40px;
	}

	.video_detail_tab {
		padding: 28px 28px 0;
		box-sizing: border-box;
		border-bottom: 1px solid #eeeeee;
	}

	.video_detail_tab div {
		font-size: 18px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 18px;
		letter-spacing: 0px;
		color: #333333;
		margin-right: 62px;
		padding-bottom: 15px;
		border-bottom: 2px solid transparent;
	}

	.video_detail_tab div.active {
		color: #5957ff;
		border-color: #5957ff;
	}

	.video_detail_work {
		padding-bottom: 43px;
		padding-left: 30px;
		padding-right: 30px;
		border-bottom: 1px solid #eeeeee;
	}

	.video_detail_job {
		width: 100%;
		height: 220px;
		background-color: #eff3f5;
		border-radius: 5px;
		border: dashed 1px #dddddd;
		padding: 18px;
		margin: 24px auto 0;
		box-sizing: border-box;
	}

	.video_detail_job_close {
		padding: 8px 8px;
		background-color: rgba(0, 0, 0, 0.7);
		color: rgba(255, 255, 255, 0.5);
		font-size: 12px;
		text-align: center;
		position: absolute;
		top: 0;
		right: 0;
		border-radius: 0 0 0 6px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		cursor: pointer;
	}

	.video_detail_job_close img {
		width: 8px;
		height: 8px;
		/* 	margin-right: 4px; */
	}

	.video_detail_job>>>.el-upload-dragger {
		width: 279px;
		height: 184px;
		box-sizing: border-box;
		position: relative;
	}

	.video_detail_job>>>.el-upload__button {
		width: 160px;
		height: 40px;
		text-align: center;
		background-color: #5957ff;
		border-radius: 10px;
		margin: 46px auto 10px;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 40px;
		letter-spacing: 0px;
		color: #ffffff;
	}

	.video_detail_job>>>.el-upload__text {
		width: 115px;
		margin: 0 auto;
		text-align: center;
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 20px;
		letter-spacing: 0px;
		color: #999999;
	}

	.video_detail_job>>>.up_img {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
	}

	.video_detail_job_introduce {
		width: 1010px;
		height: 184px;
		background-color: #ffffff;
		border-radius: 5px;
	}

	.video_detail_job_introduce textarea {
		width: 100%;
		height: 100%;
		border: dashed 1px #eeeeee;
		border-radius: 5px;
		box-sizing: border-box;
		padding: 10px 16px;
		box-sizing: border-box;
	}

	.video_detail_job_introduce textarea:focus-visible {
		outline: 0;
	}

	.video_detail_job_submit {
		width: 80px;
		height: 36px;
		background-color: #5957ff;
		border-radius: 5px;
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #ffffff;
		text-align: center;
		margin-top: 15px;
		margin-right: 28px;
		cursor: pointer;
	}

	.video_detail_review_box {
		padding: 22px 30px 30px;
		box-sizing: border-box;
	}

	.video_detail_review {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 16px;
		letter-spacing: 0px;
		color: #333333;
		margin-bottom: 18px;
	}

	.video_detail_review_list {
		box-sizing: border-box;
	}

	.video_detail_user {
		padding: 20px 0;
		border-radius: 5px;
		border: solid 1px #eeeeee;
		margin-bottom: 15px;
		position: relative;
	}

	/* 	.video_detail_user:last-child {
		border-bottom: none;
	} */

	.video_detail_user_top {
		padding: 0 25px;
	}

	.video_detail_user_top_l {
		width: 800px;
	}

	.video_detail_user_top_l img {
		width: 32px;
		height: 32px;
		border-radius: 36px;
		margin-right: 11px;
	}

	.video_detail_user_top_l_info {
		padding-left: 47px;
	}

	.video_detail_user_top_l_info div:nth-child(1) {
		font-size: 14px;
		font-weight: bold;
		font-stretch: normal;
		line-height: 14px;
		letter-spacing: 0px;
		color: #333333;
		margin-bottom: 20px;
		margin-top: 11px;
	}

	.video_detail_user_top_l_info div:nth-child(2) {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 24px;
		letter-spacing: 0px;
		color: #333333;
		margin-bottom: 5px;
		letter-spacing: 1px;
		min-height: 24px;
		white-space: pre-wrap;
	}

	.video_detail_user_time {
		font-size: 12px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 36px;
		letter-spacing: 0px;
		color: #999999;
	}

	.top_time {
		position: absolute;
		top: 65px;
		right: 75px;
		z-index: 1;
	}

	.video_detail_user_jx {
		width: 26px;
		height: 26px;
		padding: 5px 20px;
	}

	.video_detail_user_jx img {
		width: 100%;
		height: 100%;
	}

	.video_detail_user_del {
		position: absolute;
		z-index: 10;
		top: 35px;
		right: 60px;
	}

	/* 	.video_detail_user_del:hover {
		color: #f00;
	}
 */
	.video_detail_user_img {
		display: inline-block;
		margin-top: 5px;
		margin-bottom: 20px;
		margin-left: 67px;
		/* width: auto; */
		background-color: #ffffff;
		overflow: hidden;
		width: 100%;
		box-sizing: border-box;
	}

	.video_detail_user_imgs {
		margin-left: 0;
		padding-left: 67px;
	}

	/* .video_detail_user_img img {
		width: auto;
		height: 100%;
	} */

	.video_detail_comment {
		margin-top: 20px;
		margin-left: 48px;
		width: calc(100% - 96px);
		padding: 24px 0;
		box-sizing: border-box;
		border-top: 1px #eeeeee solid;
	}

	.comment_reply {
		position: relative;
		margin-bottom: 6px;
	}

	.video_detail_comment .comment_time {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.video_detail_edit {
		/* padding-left: 47px; */
		/* margin-top: 20px; */
		color: #5957ff !important;
		font-size: 12px;
		cursor: pointer;
	}

	.video_detail_edit>>>.el-upload-dragger {
		background: transparent;
		border: none;
		border-radius: 0;
		box-sizing: border-box;
		width: auto;
		height: auto;
	}

	.imgDolg {
		width: 100vw;
		height: 100vh;
		position: fixed;
		z-index: 9999;
		background-color: rgba(140, 134, 134, 0.6);
		top: 0;
		left: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#imgDolgClose {
		position: fixed;
		top: 35px;
		cursor: pointer;
		right: 7%;
		font-size: 50px;
		color: white;
	}

	.imgDolg img {
		max-width: 60%;
		max-height: 60%;
	}

	.nomarleft {
		margin-left: 0;
	}

	.nopaddleft {
		padding-left: 0;
	}

	.video_detail_user_del>>>.el-icon-more {
		font-size: 22px;
		color: #999;
	}

	.goods {
		position: absolute;
		right: -4px;
		width: 100px;
		height: 100px;
		top: -4px;
		background-image: url(../assets/images/goods.png);
		z-index: 1;
	}

	.goods1 {
		background-image: url(../assets/images/good_three.png);
	}

	.goods2 {
		background-image: url(../assets/images/good_two.png);
	}

	.goods3 {
		background-image: url(../assets/images/good_one.png);
	}

	.video_detail_content {
		position: relative;
	}

	.video_detail_content .zan {
		position: absolute;
		right: 54px;
		bottom: 0;
		display: flex;
		cursor: pointer;
		background: url("../assets/images/zan.svg") left center no-repeat;
		background-size: 17px;
		padding-left: 17px;
		height: 20px;
		line-height: 20px;
	}

	.video_detail_content .zan_active {
		background-size: 17px !important;
		background: url("../assets/images/zan2.svg") left center no-repeat;
	}

	.video_detail_content .zan span {
		margin-top: 2px;
		font-size: 12px;
		color: #999;
		margin-left: 3px;
	}

	.reply_content {
		margin-bottom: 8px;
		font-size: 14px;
		line-height: 24px;
		width: 800px;
		letter-spacing: 1px;
		white-space: pre-wrap;
	}

	.reply_thumb {
		position: relative;
		padding-bottom: 5px;
		box-sizing: border-box;
	}

	.reply_thumb>img {
		/* height: 100%;
  width: auto; */
		border-radius: 5px;
		border: 1px solid #dddddd;
		max-width: 40%;
		max-height: 40%;
	}

	.reply_edit {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.reply_edit div {
		display: inline-block;
		cursor: pointer;
		margin-left: 30px;
	}

	.reply_edit div img {
		width: 17px;
		height: 17px;
		display: inline-block;
		float: left;
		color: #f00;
		filter: drop-shadow(0 0 #32bb65);
		margin-right: 4px;
	}

	.reply_edit div span {
		display: inline-block;
		line-height: 17px;
		font-size: 14px;
		color: #999999;
	}

	.more_edit {
		position: relative;
		padding: 0 15px;
	}

	.more_edit_pop_top {
		position: absolute;
		left: -28px;
		top: 25px;
		height: 17px;
		width: 107px;
		background: url("../assets/images/delete_pop.png") no-repeat;
		display: none;
	}

	.more_edit_pop {
		min-height: 20px;
		width: 107px;
		position: absolute;
		left: -28px;
		top: 42px;
		background: #f6f6f6;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		padding-bottom: 10px;
		text-align: center;
		line-height: 20px;
		padding-top: 5px;
		display: none;
	}

	.more_edit_pop div {
		display: block;
		padding-left: 27px;
		background-size: 15px !important;
		height: 24px;
		line-height: 24px;
		width: 35px;
		margin: auto;
		font-size: 16px;
	}

	.more_edit_pop div:nth-child(1) {
		background: url(../assets/images/edit.svg) no-repeat left center;
		margin-bottom: 5px;
	}

	.more_edit_pop div:nth-child(2) {
		background-size: 17px !important;
		background: url(../assets/images/delete.svg) no-repeat left center;
	}

	.more_edit:hover .more_edit_pop_top {
		display: block;
	}

	.more_edit:hover .more_edit_pop {
		display: block;
	}

	.background {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(0, 0, 0, 0.5);
		z-index: 1001;
	}

	.alert {
		width: 255px;
		height: 79px;
		background-color: #ffffff;
		border-radius: 10px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-left: -174px;
		margin-top: -75px;
		z-index: 1002;
		padding: 36px 46px;
	}

	.alert>div:first-child {
		font-weight: bold;
		text-align: center;
		font-size: 18px;
	}

	.alert .alert_btn {
		text-align: center;
		line-height: 39px;
		margin-top: 29px;
	}

	.alert .alert_btn div {
		width: 114px;
		height: 39px;
		border-radius: 20px;
		font-size: 18px;
		display: inline-block;
		cursor: pointer;
	}

	.alert .alert_btn .alert_btn_cencel {
		background-color: #e5e5e5;
		color: #333;
		float: left;
	}

	.alert .alert_btn .alert_btn_sure {
		background-color: #5857ff;
		color: #fff;
		float: right;
	}

	.edit_task_pop {
		position: fixed;
		width: 820px;
		height: 556px;
		background-color: #eff3f5;
		border-radius: 8px;
		top: 50%;
		left: 50%;
		margin-top: -278px;
		margin-left: -410px;
		z-index: 1002;
		opacity: 1 !important;
	}

	.edit_task_pop_head {
		height: 60px;
		border-bottom: 1px #e0e0e0 solid;
		line-height: 60px;
		padding: 0 30px;
	}

	.edit_task_pop_head h2 {
		float: left;
		font-size: 18px;
		color: #5857ff;
		height: 59px;
		border-bottom: 2px #5857ff solid;
	}

	.edit_task_pop_head a {
		float: right;
		font-size: 28px;
		cursor: pointer;
	}

	.edit_task_pop_content_upload {
		width: 756px;
		height: 233px;
		background-color: #ffffff;
		border-radius: 5px;
		border: solid 1px #eeeeee;
		margin: 22px auto 12px;
		display: flex;
	}

	.task_pop_thumb {
		width: 210px;
		height: 210px;
		margin-left: 11px;
		margin-top: 11px;
		border: 1px #eee solid;
		background: url(../assets/images/yulan.svg) no-repeat;
		position: relative;
	}

	.task_pop_thumb img {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		margin: auto;
		max-width: 100%;
		max-height: 100%;
	}

	.task_pop_upload {
		height: 210px;
		margin-left: 11px;
		/* margin-top: 11px; */
		flex: 1;
		text-align: center;
	}

	/* .task_pop_upload .upload-demo {
  margin: 0 auto 0;
  width: 193px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: #b5b5b5;
  color: #fff;
  cursor: pointer;
  line-height: 40px;
  font-size: 16px;
} */



	.upload_el{
		width: 279px;
		height: 184px;
		border: 1px dashed #d9d9d9;
		position: relative;
		border-radius: 6px;
		cursor: pointer;
		background: #FFF;
	}
	.task_pop_upload>div {
		font-size: 12px;
		color: #999999;
		line-height: 20px;
		margin-top: 12px;
	}

	.task_pop_desc {
		width: 756px;
		height: 137px;
		background-color: #ffffff;
		border-radius: 5px;
		border: solid 1px #eeeeee;
		margin: auto;
	}

	.task_pop_desc textarea {
		width: 728px;
		height: 109px;
		outline: none;
		border: none;
		padding: 14px;
		resize: none;
	}

	.task_pop_desc textarea::placeholder {
		color: #999999;
		font-size: 14px;
	}

	.task_pop_btn {
		margin: 23px auto 0;
		text-align: center;
	}

	.task_pop_btn button {
		width: 227px;
		height: 43px;
		background-color: #5857ff;
		border-radius: 5px;
		color: #fff;
		font-size: 16px;
		outline: none;
		border: none;
		cursor: pointer;
	}

	.task_pop_upload>>>.el-upload-dragger {
		width: 193px;
		height: 40px;
		box-sizing: border-box;
		position: relative;
		border: none;
		background: none;
	}

	.upload_pop {
		position: absolute;
		left: 0;
		top: 0;
		width: 279px;
		height: 184px;
		z-index: 1;
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		box-sizing: border-box;
	}

	.upload_pop:hover {
		border: 1px dashed #409eff;
		cursor: pointer;
	}

	.dailyWord_upload_img>>>.el-upload-dragger {
		width: 461px;
		height: 208px;
		background-color: #ffffff;
		border-radius: 10px;
		border: dashed 1px #cccccc;
		position: relative;
	}

	.dailyWord_upload_img>>>.el-upload-dragger img {
		position: absolute;
		height: 100%;
		left: 50%;
		transform: translateX(-50%);
	}

	.dailyWord_upload_img .el-upload-dragger2 {
		width: 461px;
		height: 208px;
		background-color: #ffffff;
		border-radius: 10px;
		border: dashed 1px #cccccc;
		text-align: center;
		cursor: pointer;
	}

	.dailyWord_upload_img>>>.upload_icon {
		display: block;
		width: 53px;
		height: 53px;
		margin: 29px auto 23px;
		background: url(../assets/images/upload.png) no-repeat;
	}

	.dailyWord_upload_img .upload_icon2 {
		display: block;
		width: 53px;
		height: 53px;
		margin: 29px auto 23px;
		background: url(../assets/images/upload.png) no-repeat;
	}

	.dailyWord_upload_img>>>.el-upload__text {
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		line-height: 16px;
		letter-spacing: 0px;
		color: #333333;
		margin-bottom: 22px;
	}
	/* 不是会员的弹窗 */
	.memberMask{
		width: 100%;
		height: 100%;
		overflow: hidden;
		background-color: rgba(0, 0, 0, 0.4);
		position: fixed;
		top: 0;
		left: 0;
		z-index: 9999;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.member_con{
		width: 690px;
		height: 390px;
		background-color: #ffffff;
		border-radius: 25px;
		overflow: hidden;
		position: relative;
	}
	.member_close{
		width: 20px;
		height: 20px;
		position: absolute;
		top: 25px;
		right: 25px;
	}
	.member_title{
		width: 100%;
		height: auto;
		overflow: hidden;
		text-align: center;
		margin-top: 50px;
	}
	.member_title .member_bt{
		color: #5857ff;
		font-size: 34px;
		font-weight: 600;
	}
	.member_title .member_tips{
		margin-top: 14px;
		color: #999999;
		font-size: 20px;
	}
	.member_nr{
		width: 100%;
		height: auto;
		overflow: hidden;
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		padding: 0 30px;
		box-sizing: border-box;
	}
	.member_item{
		padding: 0 10px;
		box-sizing: border-box;
		text-align: center;
		font-size: 16px;
		color:#999;
	}
	.member_item img{
		width: 47px;
		height: 45px;
		margin:0 auto 20px;
	}
	.member_btn{
		width: 312px;
		height: 53px;
		border-radius: 26px;
		overflow: hidden;
		background-color: #5857ff;
		color: #ffffff;
		text-align: center;
		line-height: 53px;
		margin: 38px auto 0;
		font-size: 22px;
		cursor: pointer;
	}
</style>
