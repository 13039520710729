<template>
    <div :class="isMask==1?'collectionMask':'collection'" id="child">
        <div class="collection_head">
            选择收藏夹
            <img @click.stop="$parent.closeFun(1)" src="../assets/images/close.png" alt="">
        </div>
        <div class="favorite">
            <div class="favorite_item" v-for="(item,index) in favoriteList" :key="index">
                <div class="favorite_item_name"  @click="addFavorite(item.id)">{{item.name}}</div>
                <!-- <div class="favorite_item_button">收藏</div> -->
            </div>
        </div>
        <div class="collection_add">
            <div class="collection_add_button" @click="isShow = true"><img src="../assets/images/add_01.svg" alt="">创建收藏夹</div>
            <div class="collection_add_box" :class="isShow? 'show':''">
                <div class="collection_add_input">
                    <input type="text" v-model="favoriteName" placeholder="输入收藏夹名称" @keyup.enter="newAddFun">
                    <div class="collection_add_input_save" @click="newAddFun">新建</div> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'collection',
    props:['isMask','type','collectionId'],
    data() {
        return{
            isShow:false,

            favoriteList:[],//收藏夹列表
            favoriteName:'',//新建收藏夹名称 
        }
    },
    mounted(){
        console.log(this.type)
        this.initList()
    },
    methods:{
        //收藏夹列表
        initList(){
            var that = this;
            this.$api.POST(this.$face.favoritesIndex,{type: this.type},function(res){
                that.favoriteList = res.data
            })
        },
        //收藏到收藏夹
        addFavorite(id){
            var that = this;
            var params ={
                coll_id: this.collectionId,
                type: this.type,
                favorite_id: id,
            }
            this.$api.POST(this.$face.collectionColl,params,function(res){
                that.$parent.closeFun(2);
                that.$utile.prompt('success','收藏成功');
                that.$parent.info.is_collect = 1;
                
            })
        },
        //新建文件夹
        newAddFun(){
            var that = this;
            var params ={
                name: this.favoriteName,
                type: this.type,
            }
            this.$api.POST(this.$face.favoritesAdd,params,function(res){
                that.favoriteName = '';
                that.initList()
            })
        }
    }
}
</script>

<style scoped>
    .collection{
        width: 196px;
        background-color: #FFFFFF;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 4px 10px 0px 
		rgba(172, 172, 172, 0.19);
    }
    .collectionMask{
        position: absolute;
        background-color: #FFFFFF;
        width: 400px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        overflow: hidden;
    }
    .collection_head{
        width: 100%;
        height: 46px;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 14px;
        letter-spacing: 0px;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .collection_head img{
        transition: 1s;
    }
    /* .collection_head img:hover{
        transform: rotate(270deg);
    } */


    .favorite{
        padding: 8px 6px 8px 10px;
        box-sizing: border-box;
        min-height: 160px;
    }
    .favorite_item{
        width: 100%;
        padding: 4px;
        border-radius: 8px;
        box-sizing: border-box;
        position: relative;
        transition: 0.3s;
		cursor: pointer;
    }
    .favorite_item_name{
        font-size: 14px;
        line-height: 30px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #333333;
        padding-right: 70px;
        box-sizing: border-box;
		width: 100%;
		padding-left: 10px;
		text-align: left;
    }
    .favorite_item_button{
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 50px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background-color: #5957FF;
        border-radius: 10px;
        font-size: 14px;
        letter-spacing: 0px;
        color: #ffffff;
        cursor: pointer;
    }
    .favorite_item:hover{
        background-color:rgba(248, 235, 255, 0.6);
    }
    .favorite_item:hover .favorite_item_button{
        display: block;
    }




    .collection_add{
        border-top: 1px solid #eee;
        height: 42px;
        position: relative;
    }
    .collection_add_button{
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 36px;
        letter-spacing: 0px;
        color: #333333;
        cursor: pointer;
    }
    .collection_add_button img{
        width: 18px;
        height: 18px;
        margin-right: 4px;
    }
    .collection_add_box{
        position: absolute;
        top: 42px;
        left: 0;
        right: 0;
        opacity: 0;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.4s;
    }
    .collection_add_input{
        width: calc(100% - 20px);
        height: 28px;
        line-height: 28px;
        font-size: 12px;
        background-color: #f1f1f1;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
    }
    .collection_add_input input{
        background: transparent;
        width: 100%;
        height: 100%;
        padding: 0 10px;
        box-sizing: border-box;
    }
    .collection_add_input_save{
        position: absolute;
        top: 0;
        right: 0;
        width: 54px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        background: #5957FF;
        cursor: pointer;
    }
    .collection_add_box.show{
        top: 0;
        opacity: 1;
    }
</style>